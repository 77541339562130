@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

@font-face {
  font-family: 'AutoPlates';
  src: url('./fonts/AutoPlates.woff2') format('woff2');
  font-display: swap;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FAF9FD;
}


.hide {
  display: none;
}

select {
  -webkit-appearance:none;
}
