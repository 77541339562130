.Navbar {
    padding: 10px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 5px #88888847;
    height: 5vh;
    line-height: 5vh;
    display: flex;
    justify-content: space-between;
    .title {
        font-family: 'Roboto', sans-serif;
        font-size: 1.1em;
        font-weight: 500;
        text-decoration: none;
        color: black;
        cursor: pointer;
    }
    .NavbarContainer {
        display: flex;
        width: 100vw;
        justify-content: space-between;
    }
    .bars {
        height: 3vh;
        width: auto;
        margin: auto 0;
        padding-right: 10px;
    }
    .BrowserNavbarOptions {
        .link {
            text-decoration: none;
            color: black;
            padding: 10px;
        }
        .call {
            background-color: #0652DD;
            color: white;
            font-weight: 400;
            border-radius: 5px;
            font-size: 1em;
            margin-left: 20px;
        }
    }
}
.NavbarOptions {
    background-color: white;
    border-top: 1px solid #0000004a;
    .link {
        display: block;
        text-decoration: none;
        color: #6c6c6c;
        font-size: .9em;
        padding: 10px;
        padding-left: 20px;
        border-bottom: 1px solid #c6c6c652;
    }
}
@media only screen and (min-width: 768px) {
    .Navbar {
        height: 3vh;
        line-height: 3vh;
        padding: 10px 20px;
        .title {
            font-size: 1.5em;
        }
        .NavbarContainer {
            width: 60vw;
            margin: auto;
        }
    }
}
